// AddUrls.js
import React, { useState } from 'react';
import axios from 'axios';

const host = 'https://yt-lang-flask.fly.dev/bulk'

const AddUrls = () => {
  const [urls, setUrls] = useState('');
  const [response, setResponse] = useState('');

  const handleInputChange = (e) => {
    setUrls(e.target.value);
  };

  const handleAddUrls = async () => {
    const urlsArray = urls
      .split(/\n|,/) // Split by new line or comma
      .map((url) => url.trim()) // Trim whitespace
      .filter((url) => url !== ''); // Remove empty strings

    try {
      const response = await axios.post(host, { "language":"unk", urls: urlsArray });
      setResponse(response.data);
    } catch (error) {
      console.error('Error adding URLs:', error);
      setResponse('An error occurred while adding URLs.');
    }
  };

  return (
    <div className="container">
      <h2>Add YouTube URLs for indexing</h2>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <textarea
          placeholder="Enter YouTube URLs, each on a new line or comma separated"
          value={urls}
          onChange={handleInputChange}
        />
        <button onClick={handleAddUrls}>Add URLs</button>
      </div>
      {response && <p>{response.status}</p>}
    </div>
  );
};

export default AddUrls;
