// BrutalYouTubePlayer.js
import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import './BrutalYouTubePlayer.css';

const BrutalYouTubePlayer = ({ video }) => {
  const [isPaused, setIsPaused] = useState(false);
  const [player, setPlayer] = useState(null);
  const [showCaptions, setShowCaptions] = useState(false);

  const toggleCaptions = () => {
     if (player) {
      const captionsState = !showCaptions;
      setShowCaptions(captionsState);
      if(captionsState) {
        player.loadModule("captions");
        player.loadModule("cc");
        // player.loadModule("captions");  //Works for html5 ignored by AS3
        // player.loadModule("cc");  //Works for AS3 ignored by html5
      } else {
        player.unloadModule("captions");  //Works for html5 ignored by AS3
        player.unloadModule("cc");  //Works for AS3 ignored by html5
      }
    }
  };

  useEffect(() => {
    return () => {
      // Clean up event listeners or other resources here if necessary
    };
  }, [video]);

  const onPlayerReady = (event) => {
    setPlayer(event.target);
    setShowCaptions(true);
    event.target.setPlaybackRate(0.7);
    event.target.loadModule("captions");
    event.target.loadModule("cc");
    event.target.setOption('captions', 'track');
  };

  const handlePlayPause = () => {
    if (player) {
        setIsPaused(!isPaused);

        if (isPaused) {
            player.playVideo();
        } else {
            player.pauseVideo();
        }
    }
  };

  const handlePlaybackSpeedChange = (speed) => {
    if (player) {
        player.setPlaybackRate(speed);
    }
  };
  const jumpBack = () => {
    if (player) {
      const currentTime = player.getCurrentTime();
      const newTime = Math.max(0, currentTime - 5);
      player.seekTo(newTime);
    }
  };

  return (
    <div className='container'>
        <div className="brutal-youtube-player">
        <YouTube videoId={video.yt_id} onReady={onPlayerReady} opts={{ playerVars: { modestbranding: 1, start: video.start_time_seconds } }} />
        <div className="controls">
            <button onClick={jumpBack}>Jump Back 5s</button>
            <button onClick={handlePlayPause}>{isPaused ? 'Play' : 'Pause'}</button>
            <button onClick={toggleCaptions}>{showCaptions ? 'Disable Captions' : 'Enable Captions'}</button>
            <div className="speed-controls">
            <span>Speed:</span>
            <button onClick={() => handlePlaybackSpeedChange(0.5)}>0.5x</button>
            <button onClick={() => handlePlaybackSpeedChange(0.7)}>0.7x</button>
            <button onClick={() => handlePlaybackSpeedChange(1)}>1x</button>
            <button onClick={() => handlePlaybackSpeedChange(1.5)}>1.5x</button>
            </div>
        </div>
        </div>
    </div>
  );
};

export default BrutalYouTubePlayer;
