// App.js
import React, { Component } from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import VideoSearchPage from './Pages/Search';
import Add from './Pages/Add';

class App extends Component {
    render() {
      return (
        <div className="page-container">
            <div className="header">
                <img src="/logo-elephant.png" alt="Logo" className="logo" />
                <h2>Learn a language <br />like a child learns a language</h2>
            </div>
            <Routes>
                <Route index element={<VideoSearchPage />} />
                <Route path="search" element={<VideoSearchPage />} />
                <Route path="add" element={<Add />} />
            </Routes>
        </div>
      );
    }
  }
  
  export default App;