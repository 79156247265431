// SearchBar.js
import React, { useState } from 'react';
import './SearchBar.css';
import axios from 'axios';

const SearchBar = ({ onVideoSelect }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('zh');
    const [currentPage, setCurrentPage] = useState(1);
    const [currentResultIndex, setCurrentResultIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    const host = 'https://yt-lang-flask.fly.dev/api'
    // const host = 'http://127.0.0.1:5001/api'


    const handleSearch = async () => {
        setCurrentPage(0)
        setCurrentResultIndex(0)
        search(0, 0)
    }
  
    const search = async (page, index) => {
        setLoading(true); // Set loading to true before making the API request
        try {
            const response = await axios.post(host, {
                search_term: searchTerm,
                language: selectedLanguage,
                page: page,
            });
        
            if (response.data.length > 0) {
                let prevResults = response.data
                if (page > 0) {
                    prevResults = [...searchResults, ...response.data]
                }
                setSearchResults(prevResults);
                onVideoSelect(prevResults[index]);
            } else {
                onVideoSelect(null);
            }
        } catch (error) {
            console.error('Error fetching search results:', error);
        }
        setLoading(false); // Set loading to true before making the API request
    };

    const handleNextResult = () => {
        const nextIndex = currentResultIndex + 1;
        setCurrentResultIndex(nextIndex);
      
        if (nextIndex < searchResults.length) {
          onVideoSelect(searchResults[nextIndex]);
        } else {
            const prevPage = currentPage + 1;
            setCurrentPage(prevPage);
            search(prevPage, nextIndex);
        }
      };

      const handlePrevResult = () => {
        if (currentResultIndex > 0) {
          const prevIndex = currentResultIndex - 1;
          setCurrentResultIndex(prevIndex);
          onVideoSelect(searchResults[prevIndex]);
        }
      };

  return (
    <div className="container">
      <h1>What word do you want to listen to?</h1>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Words"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select
          value={selectedLanguage}
          onChange={(e) => setSelectedLanguage(e.target.value)}
        >
          <option value="en">English</option>
          <option value="es">Spanish</option>
          <option value="zh">Chinese</option>
          <option value="vi">Vietnamese</option>
        </select>
        <button onClick={handleSearch} disabled={loading}>
          {loading ? 'Loading...' : 'Listen'}
        </button>
      </div>
      { searchResults.length > 0 && (
        
      <div className="button-row">
        <div className="far-left-button" >
        {currentResultIndex !== 0 && <button onClick={handlePrevResult}>Prev</button>}
        </div>
        <button className="far-right-button" onClick={handleNextResult}>Next</button>
    </div>
          
      )}
    </div>
  );
};

export default SearchBar;