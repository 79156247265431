// VideoSearchPage.js
import React, { useState } from 'react';
import BrutalYouTubePlayer from '../Components/BrutalYouTubePlayer/BrutalYouTubePlayer';
import SearchBar from '../Components/SearchBar/SearchBar';
import './Search.css';

const VideoSearchPage = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleVideoSelect = (video) => {
    setSelectedVideo(video);
  };

  return (
    <div className="video-search-page">
      <div className="search-section">
        <SearchBar onVideoSelect={handleVideoSelect} />
      </div>
      <div className="video-section">
        {selectedVideo && <BrutalYouTubePlayer video={selectedVideo} />}
      </div>
    </div>
  );
};

export default VideoSearchPage;
